<template>
  <div class="main">
    <header>
      <div class="content">
        <div
          class="logo"
          @click="
            () => {
              $router.push('/index');
            }
          "
          v-show="baseData.logo"
        >
          <img :src="baseData.logo" alt />
        </div>
        <div
          class="logo_title"
          @click="
            () => {
              $router.push('/index');
            }
          "
        >
          {{ baseData.systemTitle }}
        </div>
      </div>
    </header>
    <section class="login_body">
      <div class="content">
        <div class="left_wrapper">
          <div class="title">
            <div>分站开通</div>
            <div>拥有属于自己的网站</div>
          </div>

          <div class="sub_title">
            独立后台自由管理 · 24小时自动售单 · 无需人工干预
          </div>
        </div>
        <div class="right_wrapper">
          <a-form
            id="formLogin"
            class="user-layout-login"
            ref="formLogin"
            :form="form"
            @submit="handleSubmit"
          >
            <a-tabs
              :activeKey="customActiveKey"
              :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }"
              @change="handleTabClick"
            >
              <a-tab-pane key="tab1" tab="账号登陆">
                <a-form-item>
                  <a-input
                    size="large"
                    type="text"
                    placeholder="手机号码/用户名"
                    v-decorator="[
                      'username',
                      {
                        rules: [{ required: true, message: '请输入手机号' }],
                        validateTrigger: 'change'
                      }
                    ]"
                  ></a-input>
                </a-form-item>

                <a-form-item>
                  <a-input
                    size="large"
                    type="password"
                    autocomplete="false"
                    placeholder="密码"
                    v-decorator="[
                      'password',
                      {
                        rules: [{ required: true, message: '请输入密码' }],
                        validateTrigger: 'blur'
                      }
                    ]"
                  ></a-input>
                </a-form-item>
              </a-tab-pane>
              <a-tab-pane key="tab2" tab="短信登录">
                <a-form-item>
                  <a-input-group compact>
                    <a-input
                      style="width:64px;height:40px"
                      default-value="+86"
                    />
                    <a-input
                      style="width:267px;height:40px"
                      size="large"
                      type="text"
                      placeholder="手机号码"
                      v-decorator="[
                        'mobile',
                        {
                          rules: [
                            {
                              required: true,
                              pattern: /^1[3456789]\d{9}$/,
                              message: '请输入正确的手机号'
                            }
                          ],
                          validateTrigger: 'change'
                        }
                      ]"
                    />
                  </a-input-group>
                </a-form-item>
                <a-form-item>
                  <a-input-group compact>
                    <a-input
                      size="large"
                      type="text"
                      placeholder="请输入验证码"
                      style="width:221px;height:40px"
                      v-decorator="[
                        'code',
                        {
                          rules: [{ required: true, message: '请输入验证码' }],
                          validateTrigger: 'blur'
                        }
                      ]"
                    />

                    <a-button
                      style="width:110px;height:40px;color:rgba(251,52,52,1);textAlign:center;fontWeight:bold"
                      tabindex="-1"
                      :disabled="state.smsSendBtn"
                      @click.stop.prevent="getCode"
                      v-text="
                        (!state.smsSendBtn && '获取验证码') || state.time + ' s'
                      "
                    ></a-button>
                  </a-input-group>
                </a-form-item>
                <!-- <a-row :gutter="16">
                  <a-col class="gutter-row" :span="16">
                    <a-form-item>
                      <a-input
                        size="large"
                        type="text"
                        placeholder="验证码"
                        v-decorator="['code', {rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur'}]"
                      >
                        <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }" />
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col class="gutter-row" :span="8">
                    <a-button
                      class="getCaptcha"
                      tabindex="-1"
                      :disabled="state.smsSendBtn"
                      @click.stop.prevent="getCode"
                      v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"
                    ></a-button>
                  </a-col>
                </a-row>-->
              </a-tab-pane>
            </a-tabs>

            <!-- <a-form-item>
              <a-checkbox v-decorator="['rememberMe']">自动登录</a-checkbox>
              <router-link :to="{ name: 'rePwd' }" class="forge-password" style="float: right;">忘记密码</router-link>
            </a-form-item>-->

            <a-form-item style="margin-top:16px">
              <a-button
                size="large"
                type="primary"
                htmlType="submit"
                class="login-button"
                :loading="state.loginBtn"
                :disabled="state.loginBtn"
                >登录</a-button
              >
            </a-form-item>
            <div class="actions_item">
              <span
                @click="goRegister"
                style="color:rgba(0,0,0,0.45);   cursor: pointer;"
                >立即注册</span
              >
              <span v-show="customActiveKey == 'tab1'">|</span>
              <router-link
                v-show="customActiveKey == 'tab1'"
                :to="{ name: 'rePwd' }"
                style="color:rgba(0,0,0,0.45)"
                >忘记密码</router-link
              >
              <span>
                <a target="#" href="https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97817c5390744c9abd4ae0e0344549e2a29e6fdc2fdf2b2137a1eaebae5c0f1ad81b0ab6a09db04a4b9520996e25ff7c">联系客服</a>
              </span>
            </div>
          </a-form>
        </div>
      </div>
    </section>
    <footer>
      <div>© 2020 速代发 , All Rights Reserved 粤ICP备20038572</div>
    </footer>
  </div>
</template>

<script>
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha';
import { sipSms, sipLogin } from '@api';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import Vue from 'vue';

export default {
  components: {
    TwoStepCaptcha
  },
  data() {
    return {
      baseData: baseData,
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false
      },
      query: '',
      inviterCode: Vue.ls.get('DEFAULT_INVITE_KEY')
    };
  },
  mounted() {
    this.query = Vue.ls.get('DEFAULT_INVITE_KEY');
  },
  methods: {
    handleTabClick(key) {
      this.customActiveKey = key;
    },
    goRegister() {
      if (this.inviterCode) {
        this.$router.push({ name: 'register', query: { c: this.inviterCode } });
      } else {
        this.$router.push({ name: 'register' });
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      const {
        form: { validateFields },
        state,
        customActiveKey
      } = this;
      state.loginBtn = true;

      const validateFieldsKey =
        customActiveKey === 'tab1'
          ? ['username', 'password']
          : ['mobile', 'code'];
      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        const submitData = JSON.parse(JSON.stringify(values))
        if (!err) {
          if (customActiveKey == 'tab1') {
            // 用户名密码
          } else if (customActiveKey == 'tab2') {
            // 手机号验证码
            submitData.username = submitData.mobile
            delete submitData.mobile
          }
          sipLogin(submitData)
            .then((res) => {
              if (res.success) {
                const data = res.data;
                Vue.ls.set(ACCESS_TOKEN, data.token, 7 * 24 * 60 * 60 * 1000);
                this.$notification.success({
                  message: '欢迎',
                  description: `${data.username}，欢迎回来`
                });
                // 延迟 1 秒显示欢迎信息
                setTimeout(() => {
                  // window.location.href = window.location.origin;
                  if (this.$route.query.redirect) {
                    let redirect = decodeURIComponent(
                      this.$route.query.redirect
                    );
                    this.$router.push({ path: redirect });
                  } else {
                    this.$router.push({ path: '/goods/purchase' });
                  }
                }, 1000);
              } else {
                this.requestFailed(res);
              }
            })
            .catch((err) => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false;
            });
        } else {
          setTimeout(() => {
            state.loginBtn = false;
          }, 600);
        }
      });
    },
    getCode(e) {
      e.preventDefault();
      const {
        form: { validateFields },
        state,
        $message,
        $notification
      } = this;
      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);

          const hide = $message.loading('验证码发送中..', 0);
          sipSms(values.mobile, 2)
            .then((res) => {
              if (res.success) {
                setTimeout(hide, 2500);
                this.$notification['success']({
                  message: '提示',
                  description: '验证码获取成功',
                  duration: 8
                });
              } else {
                setTimeout(hide, 2500);
                this.$notification['error']({
                  message: '错误',
                  description: res.msg,
                  duration: 4
                });
              }
            })
            .catch((err) => {
              setTimeout(hide, 1);
              clearInterval(interval);
              state.time = 60;
              state.smsSendBtn = false;
              this.$notification['error']({
                message: '错误',
                description: err.message,
                duration: 4
              });
            });
        }
      });
    },
    requestFailed(err) {
      this.$notification['error']({
        message: '错误',
        description: err.msg,
        duration: 4
      });
    }
  }
};
</script>
<style lang="less" scoped>
.main {
  background-color: #fff;
  height: 938px;
  font-family: PingFang SC;
  .content {
    width: 1200px;
    margin: 0 auto;
  }
  header {
    height: 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    .content {
      display: flex;
      align-items: center;
      .logo {
        cursor: pointer;
        img {
          display: inline-block;
          width: 96px;
          height: 96px;
        }
      }
      .logo_title {
        cursor: pointer;
        font-size: 34px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        margin-left: 7px;
      }
    }
  }
  .login_body {
    background-color: #fff4f4;
    height: 600px;
    .content {
      height: 600px;
      background-image: url('../../assets/bg1.png');
      background-size: cover;
      display: flex;
      .left_wrapper {
        width: 750px;
        .title {
          margin-top: 139px;
          margin-bottom: 24px;
          font-size: 54px;

          font-weight: bold;
          line-height: 86px;
          color: rgba(0, 0, 0, 0.85);
        }
        .sub_title {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .right_wrapper {
        border-radius: 16px;
        margin: 40px 0;
        width: 410px;
        height: 520px;
        background-color: #fff;
        padding: 64px 40px;
        .user-layout-login {
          /deep/ .ant-tabs-tab {
            margin: 0;
            padding: 0;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.45);
            height: 33px;
            line-height: 33px;
            margin-bottom: 32px;
            &:nth-child(1) {
              // margin-right: 33px;
              &::after {
                content: '|';
                font-size: 24px;
                font-weight: 300;
                padding-left: 33px;
                color: rgba(224, 224, 224, 1);
                // width: 0px;
                // height: 24px;
                // border: 1px solid rgba(224, 224, 224, 1);
              }
            }
            &:nth-child(2) {
              margin-left: 33px;
            }
          }
          /deep/ .ant-tabs-tab-active {
            font-size: 24px;
            border-bottom: 0;
            color: rgba(251, 52, 52, 1);
          }
          /deep/ .ant-tabs-ink-bar {
            background-color: #fff;
            opacity: 0;
          }
          /deep/ .ant-form-item {
            margin-bottom: 16px;
          }
          button.login-button {
            width: 330px;
            height: 40px;
            background: rgba(251, 52, 52, 1);
            border-radius: 4px;
            color: #fff;
            border: none;
            margin-bottom: 48px;
          }
          .actions_item {
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgba(0, 0, 0, 0.45);
            span {
              margin: 0 4px;
            }
          }
        }
      }
    }
  }
  footer {
    background-image: url('../../assets/bg2.png');
    background-size: cover;
    height: 238px;
    padding-top: 138px;
    text-align: center;
  }
}
</style>
