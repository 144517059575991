<template>
  <div class="register_wrapper">
    <header>
      <div class="header_content">
        <div
          class="left"
          @click="
            () => {
              $router.push({ path: '/index', query: { c: $route.query.c } });
            }
          "
        >
          <div class="logo" v-show="baseData.logo">
            <img :src="baseData.logo" alt="logo" />
          </div>
          <h1 class="title">{{ baseData.systemTitle }}</h1>
        </div>
        <div
          class="right"
          @click="
            () => {
              $router.push({ path: '/index', query: { c: $route.query.c } });
            }
          "
        >
          <div class="goindex">{{ baseData.systemTitle }}首页</div>
        </div>
      </div>
    </header>
    <div class="user_layout">
      <div class="register_body">
        <div
          class="title"
          @click="
            () => {
              $router.push({ path: '/index', query: { c: $route.query.c } });
            }
          "
        >
          欢迎注册{{ baseData.systemTitle }}
        </div>
        <a-form ref="formRegister" :form="form" id="formRegister">
          <div v-show="showStep1 == true">
            <a-form-item>
              <a-input-group compact>
                <a-input style="width:64px;height:40px" default-value="+86" />
                <a-input
                  style="width:267px;height:40px"
                  size="large"
                  placeholder="11 位手机号"
                  v-decorator="[
                    'mobile',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入正确的手机号',
                          pattern: /^1[3456789]\d{9}$/
                        },
                        { validator: this.handlePhoneCheck }
                      ],
                      validateTrigger: ['change', 'blur']
                    }
                  ]"
                />
              </a-input-group>
            </a-form-item>

            <a-form-item>
              <a-input-group compact>
                <a-input
                  size="large"
                  type="text"
                  placeholder="请输入验证码"
                  style="width:221px;height:40px"
                  v-decorator="[
                    'code',
                    {
                      rules: [{ required: true, message: '请输入验证码' }],
                      validateTrigger: 'blur'
                    }
                  ]"
                />

                <a-button
                  style="width:110px;height:40px;color:rgba(251,52,52,1);textAlign:center;fontWeight:bold"
                  tabindex="-1"
                  :disabled="state.smsSendBtn"
                  @click.stop.prevent="getCode"
                  v-text="
                    (!state.smsSendBtn && '获取验证码') || state.time + ' s'
                  "
                ></a-button>
              </a-input-group>
            </a-form-item>

            <a-form-item>
              <a-button
                size="large"
                type="primary"
                class="next_step"
                @click="nextStep"
                >立即注册</a-button
              >
            </a-form-item>
            <div class="gologin_now">
              已有账号，
              <router-link :to="{ name: 'login' }">
                <span>立即登录？</span>
              </router-link>
            </div>
          </div>
          <div v-show="showStep2 == true">
            <a-form-item>
              <a-input
                size="large"
                placeholder="用户名"
                v-decorator="[
                  'username',
                  {
                    rules: [
                      {
                        required: true,
                        message: '小写字母开头，加数字，6~16位',
                        pattern: /^[a-zA-Z]\w{5,16}$/
                      }
                    ]
                  }
                ]"
              />
            </a-form-item>
            <a-popover
              placement="rightTop"
              trigger="click"
              :visible="state.passwordLevelChecked"
            >
              <template slot="content">
                <div :style="{ width: '200px' }">
                  <div :class="['user-register', passwordLevelClass]">
                    强度：
                    <span>{{ passwordLevelName }}</span>
                  </div>
                  <a-progress
                    :percent="state.percent"
                    :showInfo="false"
                    :strokeColor="passwordLevelColor"
                  />
                  <div style="margin-top: 10px;">
                    <span
                      >请至少输入 6 个字符。请不要使用容易被猜到的密码。</span
                    >
                  </div>
                </div>
              </template>
              <a-form-item>
                <a-input-password
                  size="large"
                  type="password"
                  @click="handlePasswordInputClick"
                  autocomplete="false"
                  placeholder="密码"
                  v-decorator="[
                    'password',
                    {
                      rules: [
                        { required: true, message: '至少6位密码，区分大小写' },
                        { validator: this.handlePasswordLevel }
                      ],
                      validateTrigger: ['change', 'blur']
                    }
                  ]"
                ></a-input-password>
              </a-form-item>
            </a-popover>

            <a-form-item>
              <a-input-password
                size="large"
                type="password"
                autocomplete="false"
                placeholder="再次确认密码"
                v-decorator="[
                  'rePassword',
                  {
                    rules: [
                      { required: true, message: '至少6位密码，区分大小写' },
                      { validator: this.handlePasswordCheck }
                    ],
                    validateTrigger: ['change', 'blur']
                  }
                ]"
              ></a-input-password>
            </a-form-item>

            <a-form-item>
              <a-button
                size="large"
                type="primary"
                htmlType="submit"
                class="register-button"
                :loading="registerBtn"
                @click.stop.prevent="handleSubmit"
                :disabled="registerBtn"
                >完成注册</a-button
              >
            </a-form-item>

            <a-form-item>
              <a-button size="large" style="width:100%" @click="goBackStep"
                >返回</a-button
              >
            </a-form-item>
          </div>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin.js';
import { sipSms, sipRegister, accountInviterCheck } from '@api';

const levelNames = {
  0: '低',
  1: '低',
  2: '中',
  3: '强'
};
const levelClass = {
  0: 'error',
  1: 'error',
  2: 'warning',
  3: 'success'
};
const levelColor = {
  0: '#ff0000',
  1: '#ff0000',
  2: '#ff7e05',
  3: '#52c41a'
};
export default {
  components: {},
  mixins: [mixinDevice],
  data() {
    return {
      baseData: baseData,
      showStep1: true,
      showStep2: false,
      form: this.$form.createForm(this),
      inviterCode: '',
      state: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: '#FF0000'
      },
      registerBtn: false
    };
  },
  mounted() {
    this.getUrlQuery();
  },
  created() {
    const inviterCode = this.$route.query.c;
    if (inviterCode) {
      // 先检查是否通过
      accountInviterCheck(inviterCode).then((check) => {
        if (check.success) {
          this.inviterCode = inviterCode;
        }
      });
    }
  },
  computed: {
    passwordLevelClass() {
      return levelClass[this.state.passwordLevel];
    },
    passwordLevelName() {
      return levelNames[this.state.passwordLevel];
    },
    passwordLevelColor() {
      return levelColor[this.state.passwordLevel];
    }
  },
  methods: {
    getUrlQuery() {
      if (this.$route.query.c) {
        this.$store.dispatch('ToggleInviteKey', this.$route.query.c);
      }
    },
    nextStep() {
      this.form.validateFields(['mobile', 'code'], (err, values) => {
        if (values.mobile && values.code) {
          if(/^(170|171|166)\d{8}$/.test(values.mobile)) return this.$message.error('虚拟号不支持注册本系统')
          this.showStep1 = false;
          this.showStep2 = true;
        }
      });
    },
    goBackStep() {
      this.showStep1 = true;
      this.showStep2 = false;
    },
    handlePasswordLevel(rule, value, callback) {
      let level = 0;

      // 判断这个字符串中有没有数字
      if (/[0-9]/.test(value)) {
        level++;
      }
      // 判断字符串中有没有字母
      if (/[a-zA-Z]/.test(value)) {
        level++;
      }
      // 判断字符串中有没有特殊符号
      if (/[^0-9a-zA-Z_]/.test(value)) {
        level++;
      }
      this.state.passwordLevel = level;
      this.state.percent = level * 30;
      if (level >= 2) {
        if (level >= 3) {
          this.state.percent = 100;
        }
        callback();
      } else {
        if (level === 0) {
          this.state.percent = 10;
        }
        callback(new Error('密码强度不够'));
      }
    },
    handlePasswordCheck(rule, value, callback) {
      const password = this.form.getFieldValue('password');
      if (value === undefined) {
        callback(new Error('请输入密码'));
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error('两次密码不一致'));
      }
      callback();
    },
    handlePhoneCheck(rule, value, callback) {
      callback();
    },
    handlePasswordInputClick() {
      if (!this.isMobile()) {
        this.state.passwordLevelChecked = true;
        return;
      }
      this.state.passwordLevelChecked = false;
    },
    handleSubmit() {
      const {
        inviterCode,
        form: { validateFields }
      } = this;
      validateFields((err, values) => {
        if (!err) {
          // 如果通过邀请码进入的话，吧c设置上
          if (inviterCode) {
            values.inviterCode = inviterCode;
          }
          // 设置注册方式
          values.type = 1;
          // 设置来源站
          values.host = baseData.host;
          values.oemUid = baseData.uid;
          this.registerBtn = true;
          sipRegister(values)
            .then((res) => {
              if (res.success) {
                this.$notification.success({
                  message: '欢迎',
                  description: `注册成功，请前往登录`
                });
                this.$router.push({ name: 'login' });
              } else {
                this.$notification['error']({
                  message: '错误',
                  description: res.msg,
                  duration: 4
                });
              }
              this.registerBtn = false;
            })
            .catch((err) => {
              console.log('err', err);
              this.registerBtn = false;
            });
        }
      });
    },
    getCode(e) {
      e.preventDefault();
      const {
        form: { validateFields },
        state,
        $message
      } = this;
      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          if(/^(170|171|166)\d{8}$/.test(values.mobile)) return this.$message.error('虚拟号不支持注册本系统')
          state.smsSendBtn = true;

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);

          const hide = $message.loading('验证码发送中..', 0);
          sipSms(values.mobile, 1)
            .then((res) => {
              if (res.success) {
                setTimeout(hide, 2500);
                this.$notification['success']({
                  message: '提示',
                  description: '验证码获取成功',
                  duration: 8
                });
              } else {
                setTimeout(hide, 2500);
                this.$notification['error']({
                  message: '错误',
                  description: res.msg,
                  duration: 4
                });
              }
            })
            .catch((err) => {
              setTimeout(hide, 1);
              clearInterval(interval);
              state.time = 60;
              state.smsSendBtn = false;
              this.$notification['error']({
                message: '错误',
                description: err.message,
                duration: 4
              });
            });
        }
      });
    }
  },
  watch: {
    'state.passwordLevel'(val) {}
  }
};
</script>
<style lang="less" scoped>
.register_wrapper {
  background: #f8f8f8;
  font-family: PingFang SC;
  min-width: 1200px;
  header {
    height: 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    .header_content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      // background-color: pink;
      .left {
        cursor: pointer;
        display: flex;
        align-items: center;
        .logo {
          img {
            display: inline-block;
            width: 96px;
            height: 96px;
            margin-right: 8px;
            background: #fff;
            // object-fit: cover;
          }
        }
        .title {
          font-size: 26px;
          font-weight: 500;
          color: #000000;
          margin: 0;
        }
      }
      .right {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        .goindex {
          height: 20px;
          line-height: 20px;
          color: #f93244;
          font-size: 14px;
        }
      }
    }
  }
  .user_layout {
    background: #f8f8f8;

    display: flex;
    justify-content: center;
    align-content: center;

    .register_body {
      width: 850px;
      height: 586px;
      padding: 0 260px;
      border-radius: 16px;
      margin-top: 80px;
      margin-bottom: 220px;
      background: rgba(255, 255, 255, 1);

      .title {
        cursor: pointer;
        margin-top: 80px;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        margin-bottom: 56px;
      }
      .user-register {
        &.error {
          color: #ff0000;
        }

        &.warning {
          color: #ff7e05;
        }

        &.success {
          color: #52c41a;
        }
      }
      /deep/ .ant-form-item {
        margin-bottom: 16px;
      }

      .register-button {
        width: 100%;
        background-color: #fb3434;
        color: #fff;
        border: none;
        margin-top: 16px;
      }
      .next_step {
        width: 100%;
        background-color: #fb3434;
        color: #fff;
        border: none;
        margin: 16px 0;
      }
      .gologin_now {
        margin-top: 16px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
      }
    }
  }
}
</style>
